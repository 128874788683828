import { Link } from 'react-router-dom';

import styles from './Header.module.css'

import back from "../assets/img/back-button.svg"
import Alert from '@mui/material/Alert';

function Header({linkType, title, btn1, btn2}) {
  return (
    <>
    <Alert variant="filled" severity="warning" sx={{fontSize: "1.1rem"}}>
    Sus cursos pronto se cerrarán (el 28-Febrero). Una vez cerrado podrá continuar descargando sus apuntes y viendo las clases pero no podrá realizar entregas. Ante cualquier consulta escribimos al email de alumnos@inovecode.com
    </Alert>
    <header className={styles.container}>
      <div className={styles.title}>
        {linkType === "back" ? 
          <Link to={-1}>
            <img src={back} alt="inove" />
          </Link>
          :
          null
          // <a href="http://inovecode.com.ar/campus/">
          //   <img src={home} alt="inove" />
          // </a>
        }
          {title}
      </div>
      <div>
        {btn1}
        {btn2}
      </div>
      {/* <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
            {username? username[0].toUpperCase() : "."}
      </Avatar> */}
    </header>
    </>
  )
}

export default Header